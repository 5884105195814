import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  iframeUrl: string = "https://bestpass.com/privacy-policy-unlisted";
  // Old link : https://bestpass.com/privacy-policy
  constructor(@Inject(MAT_DIALOG_DATA) public data: { url: string }) {
    // this.iframeUrl = this.sanitizeUrl(data.url);
    // console.log(this.iframeUrl);
  }
  private sanitizeUrl(url: string): string {
    return url; 
  }
  ngAfterViewInit(): void {
    
  }
}
